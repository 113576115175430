<template>
    <b-card-code no-body title="Users reported">
        <div class="mx-2 mb-2">
            <b-row>
                <!-- Per Page -->
                <b-col
                cols="12"
                md="6"
                class="d-flex mb-1 mb-md-0"
                >
                <!-- page length -->
                <b-form-group
                    label="Show"
                    label-cols="5"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                    />
                </b-form-group>
                </b-col>
            </b-row>
        </div>
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="users_reported_data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: username text -->
            <template #cell(username)="data">
                <span v-if="data.item.user != null">{{ data.item.user.username }}</span>
            </template>

            <!-- Column: reaction type of report text -->
            <template #cell(type_of_report)="data">
                <span v-if="data.item.user != null">{{ data.item.report_type.name }}</span>
            </template>

            <!-- Column: Date of report -->
            <template #cell(date_of_report)="data">
                <span v-if="data.item.user != null">{{ data.item.created_at | prepareDate }}</span>
            </template>

            <!-- Column: Ban user -->
            <template #cell(ban_user)="data">
                <b-badge v-if="data.item.user != null" variant="light-danger" @click="banUser(data.item.user_id)" v-b-tooltip.hover.top="'Ban user'">
                    <feather-icon icon="SlashIcon" />
                </b-badge>
            </template>

        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import UsersReportedTable from "./UsersReportedTable.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '../../../main.js';
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
        UsersReportedTable
    },
    props: ["users_reported_data"],
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            data: [],
            searchReportType: "",
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "username", label: "Username" },
                { key: "type_of_report", label: "Reason of report" },
                { key: "date_of_report", label: "Date of report", class: "text-nowrap" },
                { key: "ban_user", label: "Option" },
            ],
        };
    },
    methods: {
        banUser(user_id) {
            this.$bvModal
                .msgBoxConfirm("The selected user will be banned!", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/ban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The selected user was banned!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        EventBus.$emit('userReportedBanned');                                   
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error banning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

</style>