<template>
    <b-card-code no-body title="All report types">
        <div class="mx-2 mb-2">
            <div class="d-flex align-items-center justify-content-between mt-2">
                <!-- Per Page -->
                <div cols="6" md="6">
                    <!-- page length -->
                    <b-form-group
                        label="Show"
                        label-cols="5"
                        label-align="left"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="text-nowrap mb-md-0 mr-1"
                    >
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        id="input-23"
                        v-model="searchReportMessage"
                        type="text"
                        size="sm"
                        placeholder="Search report message"
                        required
                    />
                </div>
            </div>
        </div>

        <!-- Table -->
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="filteredList"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: reaction text -->
            <template #cell(message_text)="data">
                <span>{{ data.item.reaction_text}}</span>
            </template>

            <!-- Column: reaction response text -->
            <template #cell(message_response_text)="data">
                <span>{{ data.item.reaction_response_text}}</span>
            </template>

            <!-- Column: Questionnaire name -->
            <template #cell(questionnaire)="data">
                <span>{{ data.item.userquestionnaire.userquestionnairetook.questionnaire.title }}</span>
            </template>

            <!-- Column: Questionnaire Category text -->
            <template #cell(category)="data">
                <span>{{ data.item.userquestionnaire.userquestionnairetook.questionnaire.subcategory.questionnairecategory.name}}</span>                
            </template>

            <!-- Column: Questionnaire submission -->
            <template #cell(questionnaire_submission)="data">
                <router-link :to="{name: 'questionnaire-submission', params: {submission_id: data.item.userquestionnaire.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        View
                    </span>
                </router-link>    
            </template>

            <!-- The toggle show/hide users reported btn -->
            <template #cell(reported_by)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Hide' : 'Show'}}
                </b-button>
            </template>

            <template #cell(reaction_options)="data">
                <b-badge class="px-025" variant="light-danger" @click="removeReaction(data.item.id)" v-b-tooltip.hover.top="'Remove message'">
                    <feather-icon icon="Trash2Icon" />
                </b-badge>
                <b-badge class="px-025" variant="light-danger" @click="removeReactionResponse(data.item.id)" v-b-tooltip.hover.top="'Remove message response'">
                    <feather-icon icon="Trash2Icon" />
                    <feather-icon icon="RotateCwIcon"/>
                </b-badge> 
                <b-badge class="px-025" variant="light-success" @click="removeReactionReports(data.item.id)" v-b-tooltip.hover.top="'Ignore report'">
                    <feather-icon icon="CheckCircleIcon" />
                </b-badge>
            </template>

            <template #cell(user_options)="data">
                <b-badge class="px-025" v-if="data.item.userfollow.following_u != null" variant="light-danger" @click="banUser(data.item.userfollow.user_following_id, 'user that left the message')" v-b-tooltip.hover.top="'Ban the user who left the message'">
                    <feather-icon icon="SlashIcon" />
                    <feather-icon icon="UserIcon" />
                </b-badge>
                <b-badge class="px-025" v-else variant="light-success" @click="unbanUser(data.item.userfollow.user_following_id, 'user that left the message')" v-b-tooltip.hover.top="'Ban the user who left the message'">
                    <feather-icon icon="CheckCircleIcon" />
                    <feather-icon icon="UserIcon" />
                </b-badge>

                <b-badge class="px-025" v-if="data.item.userfollow.followed_u != null" variant="light-danger" @click="banUser(data.item.userfollow.user_followed_id, 'user that left the message response')" v-b-tooltip.hover.top="'Ban the user who left the message response'">
                    <feather-icon icon="SlashIcon" />
                    <feather-icon icon="RotateCwIcon"/>
                </b-badge>
                <b-badge class="px-025" v-else variant="light-success" @click="unbanUser(data.item.userfollow.user_followed_id, 'user that left the message response')" v-b-tooltip.hover.top="'Ban the user who left the message response'">
                    <feather-icon icon="CheckCircleIcon" />
                    <feather-icon icon="RotateCwIcon"/>
                </b-badge>
            </template>
            
            <template #row-details="row">
                <users-reported-table :users_reported_data="row.item.reports"></users-reported-table>
            </template>

        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import UsersReportedTable from "./UsersReportedTable.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '../../../main.js';
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip
} from "bootstrap-vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
        UsersReportedTable,
        FeatherIcon
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            data: [],
            searchReportMessage: "",
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "message_text", label: "Message text" },
                { key: "message_response_text", label: "Message response text" },
                { key: "questionnaire", label: "Questionnaire" },
                { key: "category", label: "Questionnaire Category" },
                { key: "questionnaire_submission", label: "Submission" },
                { key: "reported_by", label: "Reported By" },
                { key: "reaction_options", label: "Reaction Options", class: "text-center" },
                { key: "user_options", label: "Users Options", class: "text-center" },
            ],
        };
    },
    computed: {
        filteredList() {
            let searchTerm = this.searchReportMessage;
            let countReportMessage = 0

            return this.data.filter(reportMessage => {
                if(reportMessage.reaction_text == null) {
                    reportMessage.reaction_text = ""
                }

                if(reportMessage.reaction_text.toLowerCase().includes(searchTerm.toLowerCase())) {
                    // Update total rows
                    countReportMessage++
                    this.totalRows = countReportMessage
                    
                    return reportMessage;
                }
            });       
        },
    },
    methods: {
        getAllReportedMessages() {
            // Return report type list
            this.$http.get("/api/auth/admin/reactions/reports")
                .then((res) => {
                    if(res != undefined) {
                        this.data = res.data
                    }
                })
                .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        removeReaction(reaction_id) {
            this.$bvModal
                .msgBoxConfirm('The selected reaction will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/" + reaction_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllReportedMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the reaction!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },     
        removeReactionResponse(reaction_id) {
            this.$bvModal
                .msgBoxConfirm('The selected reaction response will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/message/response/" + reaction_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction response was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllReportedMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the reaction!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }, 
        removeReactionReports(reaction_id) {
            this.$bvModal
                .msgBoxConfirm("The selected reaction reports will be deleted!", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/reactions/clear/" + reaction_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction response was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllReportedMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the reaction!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        banUser(user_id, banned_user_param) {
            this.$bvModal
                .msgBoxConfirm("The selected " + banned_user_param + " will be banned!", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/ban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The selected user was banned!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllReportedMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error banning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        unbanUser(user_id, banned_user_param) {
            this.$bvModal
                .msgBoxConfirm("The selected " + banned_user_param + " will be unbanned!", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.get("/api/auth/admin/users/unban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The selected user was unbanned!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllReportedMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error unbanning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }
    },
    mounted() {
        this.getAllReportedMessages();
        EventBus.$on('userReportedBanned', () => {
            this.getAllReportedMessages();
        });
    }
}
</script>

<style scoped>

</style>